<template>
  <div>
    <header class="bg-dark text-white text-center py-5">
      <h1 class="display-4">TKServices</h1>
      <p class="lead">Profesjonalne usługi w zakresie tworzenia oprogramowania</p>
    </header>
    <section class="container my-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h2>O nas</h2>
          <p>
            Jesteśmy firmą specjalizującą się w tworzeniu nowoczesnych rozwiązań software'owych.
            Z pasją do technologii oraz innowacyjnych rozwiązań pomagamy firmom na całym świecie osiągać ich cele biznesowe.
            Stworzymy aplikacje na miarę Twoich potrzeb.
          </p>
        </div>
        <div class="col-md-6">
          <img src="@/assets/team.jpg" alt="O nas" class="img-fluid rounded">
        </div>
      </div>
    </section>
    <section class="bg-light py-5">
      <div class="container">
        <h2 class="text-center mb-5">Nasze usługi</h2>
        <div class="row text-center">
          <div class="col-md-4 mb-4">
            <i class="fas fa-code fa-3x text-primary mb-3"></i>
            <h4>Rozwój oprogramowania</h4>
            <p>Tworzymy aplikacje dostosowane do Twoich potrzeb, wykorzystując najnowsze technologie i najlepsze praktyki.</p>
          </div>
          <div class="col-md-4 mb-4">
            <i class="fas fa-mobile-alt fa-3x text-primary mb-3"></i>
            <h4>Aplikacje mobilne</h4>
            <p>Projektujemy i rozwijamy aplikacje mobilne na systemy iOS i Android, które angażują użytkowników.</p>
          </div>
          <div class="col-md-4 mb-4">
            <i class="fas fa-cloud fa-3x text-primary mb-3"></i>
            <h4>Chmura i DevOps</h4>
            <p>Zoptymalizuj swoje procesy dzięki naszym rozwiązaniom chmurowym oraz usługom DevOps.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-dark text-white py-5">
      <div class="container">
        <h2 class="text-center mb-5">Skontaktuj się z nami</h2>
        <div class="row">
          <div class="col-md-6">
            <p><strong>Adres:</strong> ul. Gminna 36G, 05-506 Lesznowola</p>
            <p><strong>Email:</strong> <a href="mailto:tk@tkservices.pl" class="text-white">tk@tkservices.pl</a></p>
            <p><strong>Telefon:</strong> +48 793 133 224</p>
          </div>
         
        </div>
      </div>
    </section>
    <footer class="bg-light text-center py-3">
      <p class="mb-0">&copy; 2024 TK Services.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "MainComponent",
};
</script>

<style scoped>
header {
  background-image: url("/src/assets/hero_image.jpg");
  background-size: cover;
  background-position: center;
}
img.rounded-circle {
  object-fit: cover;
  height: 120px;
  width: 120px;
}
</style>
